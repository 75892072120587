import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import '../_styles/DataTableStyle.css';

export const DataTable = ({ data }) => {
    // make copy of data to not modify it
    let dataCopy = {...data};

    const dateList = (() => {
        const list = [];

        Object.keys(data).forEach(key => {
            data[key].schedules.forEach(schedule => {
                const { date } = schedule;
                if (!list.includes(date)) {
                    list.push(date);
                }
            })
        });
        list.sort();

        return list;
    })();

    const jobsList = (() => {
        const list = [];

        Object.keys(data).forEach(key => {
            if (!list.includes(data[key].model)) {
                list.push(data[key].model);
            }
        });
        list.sort();

        return list;
    })();

    const dataLength = Object.keys(data).length;

    return (
        <div className="dataTableContainer">
            {
                dataLength !== 0 && 
                <Table celled structured>
                    <Table.Row>
                        <Table.HeaderCell />
                        {
                            dateList.map(time => 
                                <Table.HeaderCell key={time}>
                                    { new Date(time).toDateString() }
                                </Table.HeaderCell>
                            )
                        }
                    </Table.Row>
                    {
                        jobsList.map(job => 
                            <React.Fragment key={job}>
                                <Table.Row>
                                    <Table.Cell colSpan={dateList.length + 1} />
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell className="jobHeader">
                                        { job }
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className="jobHeader" colSpan={dateList.length} />
                                </Table.Row>
                                {
                                    Object.keys(dataCopy).map(key => {
                                        if (dataCopy[key] && dataCopy[key].model === job) {
                                            const toReturn = (
                                                <Table.Row key={`${job}-${key}`}>
                                                    <Table.HeaderCell className="nameHeader" key={`header-${job}-${key}`}>
                                                        { key }
                                                    </Table.HeaderCell>
                                                    {
                                                        dateList.map(date => {
                                                            const scheduleData = dataCopy[key].schedules.find(schedule => schedule.date === date);

                                                            if (scheduleData === undefined) {
                                                                return <Table.Cell key={`${key}-${date}`} />;
                                                            }

                                                            const {
                                                                startTime,
                                                                // endTime,
                                                                ot
                                                            } = scheduleData;

                                                            return (
                                                                <Table.Cell 
                                                                    className={ot ? 'otCell' : '' }
                                                                    key={`${key}-${date}`}
                                                                >
                                                                    <p>{startTime}</p>
                                                                    {/* <p>{endTime}</p> */}
                                                                    { scheduleData.ot !== '' && <p>OT: {ot}</p> }
                                                                </Table.Cell>
                                                            );
                                                        })
                                                    }
                                                </Table.Row>
                                            );

                                            // gain (small) execution time by removing users that have been handled from copied data
                                            dataCopy[key] = undefined;
                                            return toReturn;
                                        }
                                        else {
                                            return <></>;
                                        }
                                    })
                                }
                            </React.Fragment>
                        )
                    }
                </Table>
            }
        </div>
    );
}

DataTable.defaultProps = {
    data: {}
}

process.env.NODE_ENV !== 'production' ? DataTable.propTypes = {
    data: PropTypes.object.isRequired
} : void 0;

export default DataTable;