import React, { useState } from 'react';
import DataTable from './DataTable';
import FileUploader from './FileUploader';

// import initialState from '../_tests/DataTableInitialState';
const initialState = {data: {}, dataLoaded: false};

export const App = () => {
    const [data, setData] = useState(initialState.data);
    const [dataLoaded, setDataLoaded] = useState(initialState.dataLoaded);

    const handleLoadedData = data => {
        setData(data);
        setDataLoaded(true);
    }

    return (
        <div>
            {!dataLoaded && <FileUploader onDataLoaded={handleLoadedData} /> }
            <DataTable data={data} />
        </div>
    )
};

export default App;
