const HEADERS = [
    'Model',
    'Employee Name',
    'Scheduled Date',
    'Week Day',
    'Start Time',
    'End Time',
    'OT Type'
];

/**
 * Takes raw CSV data and turns it into expected format
 * @param {string} data 
 */
export const CSVParser = data => {
    const brokenList = data.split('\n');
    
    let areAllHeadersIncluded = true;
    let line = brokenList[0].split(',');
    HEADERS.forEach((header, index) => {
        if (!line[index] === header) {
            alert(`Expected header ${header} missing`);
            areAllHeadersIncluded = false;
        }
    })

    if (!areAllHeadersIncluded) return false;

    let list = {};
parentLoop:
    for(let i = 1; i < brokenList.length; i++) {
        line = brokenList[i].split(',');

        if (line.length !== 7) {
            continue;
        }

        for (let j = 0; j < 6; j++) {
            if (!line[j]) {
                continue parentLoop;
            }
        }

        const model = line[0].trim();
        const name = line[1].trim();
        const date = line[2].trim();
        const startTime = line[4].trim();
        const endTime = line[5].trim();
        const ot = line[6].trim();

        const newSchedule = {
            date: new Date(date).getTime(),
            startTime,
            endTime,
            ot
        };

        if (!(name in list)) {
            list = {
                ...list, 
                [name]: {
                    model,
                    schedules: Array({...newSchedule})
                }
            };
        } 
        else {
            list[name].schedules.push({...newSchedule})
        }
    }

    return list;
};

export default CSVParser;