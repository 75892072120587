import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Input, Popup } from 'semantic-ui-react';
import CSVParser from '../services/CSVParser';

import '../_styles/FileUploaderStyle.css';

export const FileUploader = ({ onDataLoaded }) => {
    /**
     * Handles the file chosen by the user
     * @param {file} file
     */
    const handleChosenFile = (file) => {
        const fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(file);
    }

    const handleClick = () => {
        const fileInput = document.getElementById('file');
        fileInput.click();
    }

    /**
     * Handles reading the chosen file
     * @throws {error}
     * @todo better handle the error display to user
     */
    const handleFileRead = event => {
        let { result } = event.target ;

        const parsedData = CSVParser(result);

        if (parsedData) {
            onDataLoaded(parsedData);
        }
    }

    return (
        <div 
            className="uploaderContainer"
            onClick={ handleClick }
        >
            <Popup 
                content='Upload raw data in CSV format' 
                trigger={
                    <Icon name='upload' size="large">
                        <Input 
                            accept='.csv'
                            fluid
                            id='file'
                            label='Upload'
                            onChange={e => handleChosenFile(e.target.files[0])}
                            style={{ display: 'none' }}
                            type='file'
                        />
                    </Icon>
                }
            />
        </div>
    );
}

FileUploader.defaultProps = {
    onDataLoaded: () => {}
}

process.env.NODE_ENV !== 'production' ? FileUploader.propTypes = {
    onDataLoaded: PropTypes.func.isRequired
} : void 0;

export default FileUploader;